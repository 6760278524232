import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const createRole = createAsyncThunk('role/createRole', async (body) => {
    let data;
    try {
        const response = await apiKey.post(`/roles/`, body);
        data = response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
export const getAllRoles = createAsyncThunk('role/getAllRoles', async (body) => {
    let data;
    try {
        const response = await apiKey.get(`/roles`);
        data = response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
export const getRoleById = createAsyncThunk('role/getRoleById', async (body, thunkApi) => {
    let data;
    try {
        const response = await apiKey.get(`/roles/${body.id}`);
        data = response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
export const updateRole = createAsyncThunk('role/updateRole', async (body) => {
    let data;
    try {
        let url = '';
        if (body?.action)
            url = `/roles/${body.roleId}?action=${body.action}`;
        else
            url = `/roles/${body.roleId}`;
        const response = await apiKey.put(url, body.payload);
        data = response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
export const deleteRole = createAsyncThunk('role/deleteRole', async (id, thunkApi) => {
    let data;
    try {
        const response = await apiKey.delete(`/roles/${id}`);
        data = response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
